.c-details-list {
  @include xy-grid;
  margin-top:0;
  border-top: 1px solid $medium-grey;
  dt,dd {
    padding-top:rem-calc(6);
    padding-bottom:rem-calc(6);
    border-bottom: 1px solid $medium-grey;
    margin:0;
  }
  dt {
    @include xy-cell(4, $gutter-type:padding);
    background-color:$light-gray;
  }
  dd {
    @include xy-cell(8, $gutter-type:padding);
  }
}