.c-list-controls {
  @include xy-grid;
  &__pagination,
  &__search {
    @include xy-cell($size: 12, $gutter-output: false);
    width: calc(100%);
  }
  &__search {
    @include breakpoint(medium) {
      @include xy-cell($size: 5, $gutter-output: false);
      width: calc(41.66667%);
      .input-group {
        margin-bottom: 0;
      }
    }
  }
  &__pagination {
    @include breakpoint(medium) {
      @include xy-cell($size: 7, $gutter-output: false);
      width: calc(58.33333%);
      ul {
        margin-bottom: 0;
      }
    }
  }
}