.c-history {
  h3 {
    margin:0;
    padding:0;
    text-align:center;
  }
  &--content { width: auto; }
  &--type { width: 150px; }
  &--commission { width: 100px; }
  tbody,tfoot {
    .c-commission--commission { text-align:right; }
  }
  &__list {
    padding:0;
    margin:0;

    > li {
      position:relative;
      list-style:none;
      border-bottom:1px solid rgba(10, 10, 10, 0.25)
    }

    &--representative {
      font-size:rem-calc(14);
      display:block;
    }

    &--vendor {
      font-size:rem-calc(15);
      display:block;
      font-weight:bold;
    }

    &--period {
      font-size:rem-calc(13);
      width:100%;
    }

    &--amount {
      display:inline-block;
      background-color:#f2f2f2;
      border:1px solid rgba(10, 10, 10, 0.15);
      margin:rem-calc(4) rem-calc(4) 0 0;
      padding: rem-calc(6) rem-calc(10) 0;
      > small {
        display:block;
        font-size:rem-calc(10);
        font-weight:bold;
        line-height:rem-calc(11);
      }
    }

    &--status {
      float:right;
    }

    &--controls {
      padding: rem-calc(10) 0 0 0;
      @include xy-grid;
      //@include breakpoint(xlarge) {
      //  position: absolute;
      //  padding: 0 1rem;
      //  bottom: 0;
      //  right: 0;
      //}
      > .button {
        text-decoration: none;
        width:100%;
        margin-bottom:8px;
        @include breakpoint(large) {
          @include xy-cell(4, $gutter-output: false);
          width: calc(33.33333% - 8px);
          margin-bottom: 0;
          &:nth-child(2) {
            margin-left: rem-calc(12);
            margin-right: rem-calc(12);
          }
        }
      }
    }
  }
  &--invoice {
    .c-history {
      &__list {
        &--controls {
          > .button {
            @include breakpoint(large) {
              @include xy-cell(3, $gutter-output: false);
              width: calc(25% - 9px);
              margin-bottom:0;
              &:nth-child(2) {
                margin-left: rem-calc(12);
                margin-right: 0;
              }
              &:nth-child(3) {
                margin-left: rem-calc(12);
                margin-right: rem-calc(12);
              }
            }
          }
        }
      }
    }
  }
}