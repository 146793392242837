$form-grid: (
        small: (size: 12,),
        mobile: (size: 10, offset:1,),
        medium: (size: 8, offset:2,),
        medium-large: (size: 7, offset:2.5,),
        large: (size: 6, offset:3,),
        xlarge: (size: 5, offset:3.5,),
        xxlarge: (size: 4, offset:4,),
);

$form-column-grid-left: (
        small: (size: 12,),
        medium: (size: 7,),
);

$form-column-grid-right: (
        small: (size: 12,),
        medium: (size: 5,),
);

.l-form {

    @include xy-grid-container($padding: 0);

    @include breakpoint(mobile) {
        @include xy-grid-container;
    }

    &__inner {
        @include xy-grid;
        margin-top: 2rem;
    }

    &__main {
        padding: 0;
        @each $break, $grid in $form-grid {
            @include breakpoint(#{$break}) {
                @if map-get($grid, size) == 12 {
                    @include xy-cell(map-get($grid, size), $gutters: 0);
                } @else {
                    @include xy-cell(map-get($grid, size));
                }
                @if map-get($grid, offset) {
                    @include xy-cell-offset(map-get($grid, offset));
                }
            }
        }
    }

    &__left-column,
    &__right-column {
        padding: 0;
    }

    &__left-column {
        @each $break, $grid in $form-column-grid-left {
            @include breakpoint(#{$break}) {
                @include xy-cell(map-get($grid, size));
                @if map-get($grid, offset) {
                    @include xy-cell-offset(map-get($grid, offset));
                }
            }
        }
    }

    &__right-column {
        @each $break, $grid in $form-column-grid-right {
            @include breakpoint(#{$break}) {
                @include xy-cell(map-get($grid, size));
                @if map-get($grid, offset) {
                    @include xy-cell-offset(map-get($grid, offset));
                }
            }
        }
    }

    &__main,
    &__left-column,
    &__right-column {
        .callout {
            padding-top: rem-calc(8);
            padding-bottom: rem-calc(8);
            border-left: 0;
            border-right: 0;
            margin-bottom: 0;
            font-size: rem-calc(15);
            text-align: center;

            &.alert, &.error {
                background-color: map-get($foundation-palette, alert);
                color: $white;
            }
        }
    }

    &__header {
        color: $white;
        background-color: map-get($foundation-palette, primary);

        &--results {
            background-color: map-get($foundation-palette, secondary);
        }

        h2 {
            width: 100%;
            text-align: center;
            margin: 0;
            padding: 0;
            font-size: rem-calc(17);
            font-weight: 400;
        }
    }

    &__body {
        form {
            .button {
                margin-top: rem-calc(6);
                margin-bottom: 0;
            }

            label.button {
                margin-top: 0;
                margin-bottom: 1rem;
            }
        }

        p {
            margin-top: 0;
        }
    }
}