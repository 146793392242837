.c-back-button {
  display:none;
  @include breakpoint(medium) {
    display:block;
  }
  position:absolute;
  top:0;
  left:10px;
  .button {
    text-decoration:none;
    > i { padding-right:rem-calc(8) }
  }
}