.l-list {

    @include xy-grid-container($padding: 0);

    &__inner {
        @include xy-grid;
        margin-top: 2rem;
    }

    &__main {
        padding: 0;
        @include xy-cell(12, $gutter-output: false);
        width: 100%;
    }

    &__main {
        .callout {
            padding-top: rem-calc(8);
            padding-bottom: rem-calc(8);
            border-left: 0;
            border-right: 0;
            margin-bottom: 0;
            font-size: rem-calc(15);
            text-align: center;

            &.alert, &.error {
                background-color: map-get($foundation-palette, alert);
                color: $white;
            }
        }
    }

    &__body {
        p {
            margin-top: 0;
        }
    }
}

.l-list,
.l-detail {
    &__header {
        @include xy-grid;
        padding-top: 0.3rem;
        color: $white;
        background-color: map-get($foundation-palette, primary);

        &--results {
            background-color: map-get($foundation-palette, secondary);
        }

        @include breakpoint(medium) {
            padding-top: 1rem;
        }

        h2 {
            width: 100%;
            @include breakpoint(medium) {
                @include xy-cell(auto, $gutters: 0);
                width: auto;
                text-align: left;
            }
            text-align: center;
            margin: 0;
            padding: 0;
            font-size: rem-calc(20);
            font-weight: 500;
            line-height: rem-calc(35);
        }

        &--controls {
            white-space: nowrap;
            text-align: center;
            width: 100%;
            @include breakpoint(medium) {
                @include xy-cell(shrink, $gutters: 0);
                width: auto;
                text-align: right;
            }
        }

        .button {
            width: auto;
            display: inline-block;
            text-decoration: none;
            margin-bottom: 0;
            margin-left: rem-calc(6);
            padding: rem-calc(9) rem-calc(15);
            white-space: nowrap;
            @include button-hollow-style($color: $light-gray, $hover-lightness: 50%);

            > i {
                padding-right: rem-calc(8)
            }
        }
    }
}