/******************************************************************
  
Stylesheet: Main Stylesheet

******************************************************************/

@import "../../node_modules/foundation-sites/scss/foundation";
@import "settings/foundation";

// Select the components you want to use
@include foundation-global-styles;

// Select the grid you would like to use
// @include foundation-grid;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes(
        $base-grid: true,
        $margin-grid: true,
        $padding-grid: true,
        $block-grid: true,
        $collapse: true,
        $offset: true,
        $vertical-grid: true,
        $frame-grid: true
);

@include foundation-flex-classes;
@include foundation-button;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-top-bar;
@include foundation-badge;
@include foundation-table;
@include foundation-label;
@include foundation-pagination;
@include foundation-callout;
@include foundation-forms;
@include foundation-card;
@include foundation-progress-bar;
@include foundation-reveal;
@include foundation-close-button;

/*
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-typography;
*/

@import "mixins";

@import "layout/detail";
@import "layout/form";
@import "layout/list";
@import "layout/page";

@import "component/back-button";
@import "component/button";
@import "component/card";
@import "component/history";
@import "component/customer-list";
@import "component/details-list";
@import "component/form";
@import "component/invoice-update";
@import "component/list-controls";
@import "component/location-lookup";
@import "component/mobile-navigation";
@import "component/modal";
//@import "component/mobile-topbar";
@import "component/pagination";
@import "component/table";
@import "component/topbar";
@import "component/upload-progress";

$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";