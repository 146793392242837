/* Breakpoints */


/* Utilities */
@mixin addBorderRadius($val) {
  -moz-border-radius:$val;
  -webkit-border-radius:$val;
  border-radius:$val;
}

@mixin mappedDeclaration($declaration, $map, $context: null, $debug: false) {
  @if type-of($context) == string {
    $map2: map-get($map, $context);
    @if type-of($map2) == map {
      $map: map-merge($map, $map2);
    }
  }
  @each $breakpoint, $value in $map {
    @if type-of($value) != map {
      @include breakpoint($breakpoint) {
        @if $debug {
          @debug $breakpoint $declaration $value;
        }
        #{$declaration}: $value;
      }
    }
  }
}

// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  -webkit-transition(width 0.2s, height 0.3s ease-in-out);
//          transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions
@mixin transition($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }

  -webkit-transition: $unfoldedTransitions;
  transition: $unfoldedTransitions;
}

@function unfoldTransition ($transition) {
  // Default values
  $property: all;
  $duration: .3s;
  $easing: null; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

@mixin linearGradient($start, $end, $percentage: 100%, $type: 'horizontal'){
  $from: left;
  $to: right;
  $degrees: 90deg;
  $fromVertical: top;
  $toVertical: top;
  @if type_of($type) == number {
    $from: calc(90deg - #{$type});
    $degrees: $type;
    $fromVertical: top;
    $toVertical: bottom;
  }
  background: $start; /* Old browsers */
  background: -moz-linear-gradient($from, $start 0%, $end $percentage); /* FF3.6+ */
  background: -webkit-gradient(linear, $from $fromVertical, $to $toVertical, color-stop(0%, $start), color-stop($percentage, $end)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient($from, $start 0%, $end $percentage); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient($from, $start 0%, $end $percentage); /* Opera 11.10+ */
  background: -ms-linear-gradient($from, $start 0%, $end $percentage); /* IE10+ */
  @if $type == 'horizontal' {
    background: linear-gradient(to $to, $start 0%, $end $percentage); /* W3C */
  } @else {
    background: linear-gradient($degrees, $start 0%, $end $percentage); /* W3C */
  }
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=1 ); /* IE6-9 */
}

@mixin size($size) {
  @if length($size) == 1 {
    @if $size == auto {
      width:  $size;
      height: $size;
    }

    @else if unitless($size) {
      width:  $size + px;
      height: $size + px;
    }

    @else if not(unitless($size)) {
      width:  $size;
      height: $size;
    }
  }

  // Width x Height
  @if length($size) == 2 {
    $width:  nth($size, 1);
    $height: nth($size, 2);

    @if $width == auto {
      width: $width;
    }
    @else if not(unitless($width)) {
      width: $width;
    }
    @else if unitless($width) {
      width: $width + px;
    }

    @if $height == auto {
      height: $height;
    }
    @else if not(unitless($height)) {
      height: $height;
    }
    @else if unitless($height) {
      height: $height + px;
    }
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

// Define vertical, horizontal, or both position
@mixin center($position) {
  position: absolute;
  @if $position == 'v' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  @else if $position == 'h' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  }
  @else if $position == 'vh' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@mixin uncenter() {
  position: static;
  top:0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

@mixin resetlist() {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin fillContainer() {
  position:absolute;
  width:100%;
  height:100%;
  left:0;
  top:0;
  right:0;
  bottom:0;
}

@mixin visuallyHidden() {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin setLinkColour($color) {
  a, a:visited { color:$color; }
}

@mixin autoExpand($size) {
  max-height:0;
  padding:0;
  opacity:0;
  overflow: hidden;
  @if $size == 'small' {
    animation: expand-vertical-small .6s ease-in-out forwards;
    animation-delay: 1s;
  }/*
  @else if $size == 'large' {
    animation: expand-vertical-large .6s ease-in-out forwards;
    animation-delay: 0.5s;
  } */
}

@mixin boxShadow($h-offset, $v-offset, $blur, $spread, $color) {
  -webkit-box-shadow: $h-offset $v-offset $blur $spread $color;
  box-shadow: $h-offset $v-offset $blur $spread $color;
}

@mixin brandfont() {
  font-family: 'Averta', sans-serif;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} { @content; }
  @-moz-keyframes #{$animationName} { @content; }
  @-o-keyframes #{$animationName} { @content; }
  @keyframes #{$animationName} { @content; }
}

@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  -webkit-animation: $animations;
  -moz-animation:    $animations;
  -o-animation:      $animations;
  animation:         $animations;
}