.c-top-bar {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  z-index:2;
  @include top-bar-container;
  &__left,
  &__right {
    flex: 0 0 auto;
  }
}

.c-menu {

  &__mobile {
    @include breakpoint(medium-large) {
      display:none;
    }
  }

  @include menu-base;

  &.c-menu__main {
    display:none;
    @include breakpoint(medium-large) {
      display:flex;
    }
  }

  &.c-top-bar__menu {
    .c-menu {
      &__text {
        @include menu-text;
        line-height:65px;
        padding: 0 0 0 rem-calc(15);
        > a {
          padding: 0;
        }
      }

      &__logo {
        max-height: 50px;
      }

      &__selected {
        background: rgba(25, 61, 214, 0.56);
      }
    }
    li {
      position:relative;
      color: $white;
      > span,
      > a {
        font-size: 15px;
        font-weight: 600;
        color: $white;
        line-height: 65px;
        padding: 0 rem-calc(30);
        cursor: pointer;
      }
      &:hover {
        .c-top-bar__sub-menu {
          display:block;
        }
      }
    }
  }

  &.c-top-bar__sub-menu {
    display:none;
    position:absolute;
    top:65px;
    min-width:200px;
    background:$white;
    ul {
      margin:0;
      padding:0;
      border-bottom:1px solid $medium-grey;
      li {
        list-style:none;
        a {
          font-size: 15px;
          font-weight: 600;
          background: $white;
          color: $text-grey;
          line-height: 55px;
          border-width: 1px 1px 0 1px;
          border-style: solid;
          border-color: $medium-grey;
          &:hover {
            background-color: lighten(map-get($foundation-palette, primary), 10);
            color: $white;
          }
        }
        &.c-menu__selected {
          > a {
            background-color: map-get($foundation-palette, primary);
            color: $white;
          }
        }
      }
    }
  }
}


