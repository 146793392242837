.c-table {
  &--disabled {
    opacity:0.5;
    pointer-events: none;
  }
  &__actions {
    width: 120px;
    .button {
      width:100%;
      text-decoration:none;
      margin-bottom:rem-calc(5);
      &:last-child {
        margin-bottom:rem-calc(0);
      }
      white-space: nowrap;
    }
    @include breakpoint(medium) {
      .button {
        width:auto;
        margin-bottom:0;
      }
      width: 155px;
      &--small {
        width: 83px;
      }
      &--large {
        width: 356px;
      }
    }
  }
}