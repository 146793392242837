.c-location-lookup {
  h3 { margin-top:0; }
  .button { margin-bottom:0; }
  &__close {
    position:absolute;
    font-size:rem-calc(33);
    font-weight:200;
    top:rem-calc(7.5);
    right:rem-calc(15);
  }
  input {
    margin-bottom:0;
  }
  &__details {
    @include xy-grid;
    background-color: $light-gray;
    border: 1px solid $medium-grey;
    padding: rem-calc(6) 0 rem-calc(12);
    margin-bottom: rem-calc(8);
  }
  &__region,
  &__name {
    @include xy-cell(6)
  }
}