.c-mobile-navigation {

  background:map-get($foundation-palette, primary);
  color:#FFF;
  height:100%;
  width:100%;
  left:0;
  //right:-100%;
  overflow-x:hidden;
  overflow-y:auto;
  position:fixed;
  opacity:0;
  top:0;
  z-index:4;
  visibility: hidden;
  @include transition(all 0.3s ease);

  .c-logo {
    max-height:50px;
    text-align:center;
    margin:0 0 0 0.9375rem;
    padding:0;
  }

  &.is-active {
    display:block;
    opacity:1;
    visibility: visible;
    z-index:3;
  }

  .l-header {
    border-bottom:1px solid #2C5B7D;
    line-height:65px;
  }

  .c-mobile-navigation__toggle {
    span {
      background-color: $white;
    }
    z-index:5;
  }

  &__toggle {
    position: absolute;
    top:3px;
    right: 5px;
    @include size(46 45);
    @include breakpoint(medium-large) {
      display:none;
    }
    z-index:3;
    cursor: pointer;
    text-indent:-9999em;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    @include size(46 45);
    margin:7px 3px 0 0;
    @include transition(all 0.5s ease-in-out);
    outline: none;
    span {
      display:block;
      position:absolute;
      height:3px;
      width:27px;
      @include addBorderRadius(0.07em);
      opacity: 1;
      left:9px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      background-color: $white;
      @include transition(all 0.25s ease-in-out);
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
      &:nth-child(1) { top:15px; }
      &:nth-child(2) { top:22px; }
      &:nth-child(3) { top:29px; }
    }
    &.is-open {
      span {
        &:nth-child(1) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          top: 13px;
          left: 12px;
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          top: 32px;
          left: 12px;
        }
      }
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding:20px 16px;
  }

  &__item {
    &--feature {
      .c-mobile-navigation__link {
        color:map-get($foundation-palette, alert);
      }
    }
    &--feature-blue {
      .c-mobile-navigation__link {
        color:map-get($foundation-palette, success);
      }
    }
  }

  &__link {
    display: block;
    text-align: center;
    text-decoration: none;
    color:$white;
    padding:0 0 8px;
    font-size: rem-calc(20);
    &:active, &:focus { color: white; }
  }

  button.c-mobile-navigation__link { float: right; outline: none; }

  &__sub-wrap {
    clear: right;
    max-height: 0;
    overflow: hidden;
    @include clearfix;
    @include transition(max-height 0.4s ease-in-out);

    &.open {
      max-height: rem-calc(300);
    }
  }

  &__sub-list { float: right; }

  &__sub-item { margin-bottom: 0.3em; }

  &__sub-link {
    display: block;
    color: #4fbde8;
    padding: 0.3em;
    font-size: 16px;
    text-align: right;
  }
}
