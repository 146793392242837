.c-select-customer {
  position:relative;
  &__options {
    display:none;
    position:absolute;
    top:70px;
    left:0;
    background-color:$white;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.06);
    list-style:none;
    margin:0 0 1rem;
    padding:0;
    min-width:100%;
    border-bottom: 1px solid $medium-grey;
    li {
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: $medium-grey;
      padding:rem-calc(8) rem-calc(15);
      cursor: pointer;
      &:not(.c-select-customer__loading) {
        &:hover {
          background-color: map-get($foundation-palette, primary);
          color: $white;
        }
      }
      margin-right:rem-calc(3);
    }
  }
  &__input {
    margin:0 0 0.4rem;
  }
  &__list {
    list-style:none;
    margin:0 0 1rem;
    padding:0;
    i { cursor: pointer; margin-left:rem-calc(3); }
  }
}