$heading-typography: (
        h1: (
                small: (
                        font-size:rem-calc(30),
                        line-height:rem-calc(38),
                        margin:0,
                ),
                medium: (
                        font-size: rem-calc(37.5),
                        line-height: rem-calc(47),
                        margin:0,
                ),
                medium-large: (
                        font-size:rem-calc(45),
                        line-height:rem-calc(47),
                        margin:0,
                ),
        ),
);



.l-page {
  margin-top:rem-calc(85);
  margin-bottom:rem-calc(85);
  position:relative;
  @include xy-grid-container;
  &__inner {
    @include xy-grid;
  }
  &__heading,
  &__body {
    @include xy-cell(12);
    @each $heading, $break-typography in $heading-typography {
      @each $break, $typography in $break-typography {
        @include breakpoint(#{$break}) {
          #{$heading} {
            @each $definition, $style in $typography {
              #{$definition}: #{$style};
            }
          }
        }
      }
    }
    a {
      &.button {
        text-decoration: none;
      }
    }
  }
}