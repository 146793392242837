.c-form__item {
  &--datetime {
  }
  &--time-inner,
  &--datetime-inner {
    @include xy-grid;
  }
  &--time-item {
    &:nth-child(1) {
      @include xy-cell(6, $gutter-output: false);
      width: calc(50% - 7.5px);
    }
    &:nth-child(2) {
      @include xy-cell(6, $gutter-output: false);
      width: calc(50% - 7.5px);
      margin-left:rem-calc(15);
    }
  }
  &--datetime-item {
    &:nth-child(1) {
      @include xy-cell(3, $gutter-output: false);
      width: calc(25%);
    }
    &:nth-child(2) {
      @include xy-cell(5, $gutter-output: false);
      width: calc(41.66667%);
      select {
        width:calc(100% - 30px);
        margin-left:rem-calc(15);
        margin-right:rem-calc(15);
      }
    }
    &:nth-child(3) {
      @include xy-cell(4, $gutter-output: false);
      width: calc(33.33333%);
    }
  }
  //select {
  //}
}
.c-form__error {
  @include callout-base();
  @include callout-style(map-get($foundation-palette, alert));
  font-size:rem-calc(12);
  padding: rem-calc(8) 1rem;
  margin-top:0;
}
textarea {
  min-height:130px;
}
