.c-modal {
  &__heading {
    margin-top:0;
    margin-bottom:rem-calc(15);
  }
  &__footer {
    .button {
      min-width:rem-calc(80);
      margin-bottom:0;
      margin-right:rem-calc(3);
    }
  }
}